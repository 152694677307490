<template>
  <i class="dj-icon" :style="style" v-bind="$attrs">
    <slot />
  </i>
</template>

<script lang="ts" setup>
import type { CSSProperties } from 'vue'

defineOptions({
  name: 'DJIcon',
  inheritAttrs: false,
})
const props = defineProps({
  size: {
    type: Number,
    default: 16,
  },
  color: {
    type: String,
    default: 'inherit',
  },
})

const style = computed<CSSProperties>(() => {
  const { size, color } = props
  if (!size && !color) return {}

  return {
    fontSize: `${size}px`,
    '--color': color,
  }
})
</script>
<style>
.dj-icon {
  --color: inherit;
  height: 1em;
  width: 1em;
  line-height: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  fill: currentColor;
  color: var(--color);
  font-size: inherit;

  svg {
    height: 1em;
    width: 1em;
  }
}
</style>
